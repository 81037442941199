<template>
  <div>
    <heads :more="showMore" :states="states" :msg="title_msg"></heads>
    <div class="product_information">
      <div class="product_information_left">
        <img class="imgs1" :src="product_thumb" alt>
      </div>
      <div class="product_information_center">
        <h2>{{ product.product_name }}</h2>
        <div v-if="product.express_price" class="money_modulds">
          <img class="money_img" src="../../static/images/my_index/bingz.png" alt>
          <span class="money_num">{{ product.express_price }}</span>
        </div>
      </div>
      <div class="product_information_right">
        <p>
          <span>{{ product.quantity }}</span>
        </p>
      </div>
    </div>
    <div class="order">
      <p v-if="trace_name">
        物流公司:
        <span>{{ trace_name }}</span>
      </p>
      <p v-if="product.send_content">
        物品详情:
        <span>{{ product.send_content }}</span>
      </p>
      <p v-if="product.express_no">
        物流单号:
        <span>{{ product.express_no }}</span>
      </p>
    </div>
    <div class="wl_details">
      <h2>物流信息</h2>
      <div class="decorate"></div>
    </div>
    <div class="progress_bar">
      <van-steps direction="vertical" :active="0" active-color="#ec2e2e">
        <van-step v-for="(item,index) in Traces" :key="index">
          <h3 style="color:#333333;">{{ item.AcceptTime }}</h3>
          <p style="color:#999999;padding-top:10px;">{{ item.AcceptStation }}</p>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { get_one_address } from '@/api/my_user.js'
export default {
  components: {
    heads
  },
  data() {
    return {
      title_msg: '物流信息',
      states: 1,
      people: 999, // 挑战人数
      pjf: '83', // 平均分
      currentRate: 0,
      list: [],
      isshow: false,
      express_no: '',
      create_time: '',
      product_thumb: '',
      Traces: [],
      trace_name: '',
      product_name: '',
      quantity: 1,
      product: {},
      showMore: true
    }
  },
  mounted() {
    this.get_msg()
  },
  methods: {
    clicks() {
      if (this.isshow === false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
    get_msg() {
      var params = {
        id: this.$route.query.id
      }
      get_one_address(params).then((res) => {
        console.log(res)
        const result = res.result ? res.result : {}
        this.product = result
        this.product_thumb = result.product_thumb ? result.product_thumb : 'https://newoss.zhulong.com/forum/202403/04/54/162354g6crtgjdgxxodwja.png'
        const express_track = result.express_track ? result.express_track : {}
        this.Traces = express_track.Traces ? express_track.Traces : []
        this.trace_name = express_track.ExpressName ? express_track.ExpressName : ''
        // 追加一条，暂时默认添加，后期具体规则待定
        const last = {
          AcceptTime: result.create_time ? result.create_time : '',
          AcceptStation: '您提交了订单，请等待系统确认'
        }
        this.Traces.push(last)
        console.log(this.Traces)
        console.log(this.trace_name)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.order p:nth-child(1) {
  padding: 40px 0 0 30px;
  font-size: 25px;
  // font-weight: 600;
  letter-spacing: 1px;
  color: #222222;
}
.order p:nth-child(1) span {
  float: right;
  font-size: 25px;
  padding: 0 25px 0 0;
  color: #313131;
  letter-spacing: 1px;
}
.order p:nth-child(2) {
  font-size: 25px;
  padding: 22px 0 0 30px;
  // font-weight: 600;
  letter-spacing: 1px;
  color: #222222;
}
.order p:nth-child(2) span {
  float: right;
  font-size: 25px;
  padding: 0 30px 0 0;
  color: #313131;
  letter-spacing: 1px;
}
.order p:nth-child(3) {
  font-size: 25px;
  padding: 22px 0 0 30px;
  // font-weight: 600;
  letter-spacing: 1px;
  color: #222222;
}
.order p:nth-child(3) span {
  float: right;
  font-size: 25px;
  padding: 0 30px 0 0;
  color: #313131;
  letter-spacing: 1px;
}
.progress_bar {
  width: 100%;
}
.wl_details {
  width: 100%;
  text-align: center;
}
.wl_details h2 {
  text-align: center;
  margin-top: 60px;
  // width: 200px;
  // margin-left: calc(100% - 200px) / 2;
  // position: absolute;
  // left: 300px;
  // top: 43px;
  // z-index: -1;
}
.decorate {
  width: 145px;
  height: 10px;
  background: #f49293;
  border-radius: 7px;
  margin: 1px auto;
}

.wl_details {
  position: relative;
  width: 100%;
  height: 159px;
  // background: chocolate;
  overflow: hidden;
}
.order {
  width: 100%;
  height: 157px;
  // border-bottom: 1px solid red;
}
.money_num {
  display: inline-block;
  font-size: 33px;
  font-weight: 600;
  padding: 0 0 0 8px;
}
.money_modulds {
  width: 170px;
  height: 37px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  // position: absolute;
  // left: 0;
  // bottom: 50px;
}
.money_img {
  width: 27px;
  height: 31px;
}
.product_information_right {
  position: relative;
  width: 88px;
  height: 100%;
  // background: aqua;
  // float: left;
}
.product_information_right p {
  position: absolute;
  bottom: 35px;
  left: 25px;
  color: #b1b1b1;
  span {
    color: #000;
    padding-left: 3px;
  }
}
.product_information_left {
  width: 213px;
  height: 199px;
  // float: left;
}
.product_information_center {
  // width: 449px;
  width: calc(100% - 88px - 213px);
  height: 100%;
  // height: 199px;
  // float: left;
  position: relative;
  h2 {
    font-size: 30px;
    padding: 42px 0 0 0;
    min-height: 75px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.imgs1 {
  width: 150px;
  height: 150px;
  margin: 30px 0 0 32px;
}
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 60px;
  z-index: 1;
}
.product_information {
  width: 100%;
  height: 211px;
  border-bottom: 2px solid #fbfbfb;
  background: #fff;
  display: flex;
  align-items: center;
}
</style>
